import Vue from 'vue';

export function setupAllComponents(topLevelComponents) {
  for (const [name, component] of Object.entries(topLevelComponents)) {
    const nameLower = name.toLowerCase();
    const attr = `ls-${nameLower}`;
    const selector = `[${attr}]`;
    const matchingElements = document.querySelectorAll(selector);
    const propDefNamesOriginal = Object.keys(component.props || {});
    if (![1, 2, 3].includes(propDefNamesOriginal.length)) {
      console.error('LiveSyllabus Vue ERROR: Bad props for', name);
    }

    // Loop through all matching elements on the page, mounting them
    for (const elem of matchingElements) {
      const props = {};
      // dupe each time, since we mutate -v
      const propDefNames = Array.from(propDefNamesOriginal);
      // Attach name prop if necessary
      if (propDefNames.includes('name')) {
        props.name = elem.getAttribute('name');
        propDefNames.splice(propDefNames.indexOf('name'), 1);
      }
      // Attach value prop if necessary
      if (propDefNames.includes('value')) {
        props.value = elem.getAttribute('value');
        propDefNames.splice(propDefNames.indexOf('value'), 1);
      }

      // Attach any remaining data as another prop
      const data = elem.getAttribute(attr);
      try {
        props[propDefNames[0]] = JSON.parse(data);
      } catch {
        console.error('LiveSyllabuse Vue ERROR: Could not parse attr:', attr);
      }
      const render = h => h(component, {props});
      const vm = new Vue({render});
      vm.$mount(elem);
    }
  }
}

export function setupFetchComponents(topLevelComponents, callback) {
  let totalCount = 0;
  let responseCount = 0;
  for (const name of Object.keys(topLevelComponents)) {
    const nameLower = name.toLowerCase();
    const fetchAttr = `lsfetch-${nameLower}`;
    const dataAttr = `ls-${nameLower}`;
    const selector = `[${fetchAttr}]`;
    const matchingElements = document.querySelectorAll(selector);
    totalCount += matchingElements.length;

    // Loop through all matching elements on the page, mounting them
    for (const elem of matchingElements) {
      // Attach any remaining data as another prop
      const url = elem.getAttribute(fetchAttr);

      fetch(url)
        .then(response => response.json())
        .then(data => {
          // We have data back
          console.log('Got data:', data);
          elem.setAttribute(dataAttr, JSON.stringify(data));
          responseCount++;

          if (responseCount >= totalCount) {
            callback();
          }
        });
    }
  }
}

