
const colorscheme = {
  header: {
    bg: 'var(--theme-primary-color)',
    text: '#ffffff'
  },
  launcher: {
    bg: 'var(--theme-primary-color)'
  },
  messageList: {
    bg: '#ffffff'
  },
  sentMessage: {
    bg: '#eee',
    text: '#000'
  },
  receivedMessage: {
    bg: '#ccc',
    text: '#222'
  },
  userInput: {
    bg: '#f4f7f9',
    text: '#565867'
  },
};

export default colorscheme;
