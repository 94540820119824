<template>
  <div class="MessageBox">
    <div class="MessageBox-structure">
      <textarea
        v-model="text"
        class="MessageBox-textarea"
        :placeholder="placeholder"
        @keydown="inputHandler"
      />
      <div class="MessageBox-toolbar">
        <label class="MessageBox-button">
          <input v-model="emojiPickerVisible" type="checkbox" />
          <div class="MessageBox-buttonEmoji"></div>
        </label>
      </div>
    </div>
    <div class="MessageBox-emojiPickerWrapper" v-if="emojiPickerVisible" >
      <VEmojiPicker
        :emojiWithBorder="false"
        @select="selectEmoji"
      />
    </div>
  </div>
</template>

<script>
// MAYBE use: TipTap for the message box?
// https://github.com/ueberdosis/tiptap/issues/66
//import JitsiVideo from './JitsiVideo.vue';
import VEmojiPicker from 'v-emoji-picker';
import { throttle } from 'throttle-debounce';

import {sendMessage, setupPusher} from './apiHelpers.js';
import {
  appendMessage,
  setIsTyping,
  isScrolledToBottom,
  scrollToBottom,
  attachChatMessageEvents,
} from './domUtils.js';

export default {
  name: 'LiveChat',
  components: {
    VEmojiPicker,
  },

  props: {
    chatData: Object,
  },

  mounted() {
    setupPusher(this);
    attachChatMessageEvents(this);

    // Throttle the saveDraft method, 2s
    this.throttledSaveDraft = throttle(2 * 1000, false, this.saveDraft);

    // Throttle marking as read for 10s
    this.throttledMarkChannelAsRead = throttle(10 * 1000, false, this.markChannelAsRead);
  },

  data() {
    const {
      placeholder,
      initialText
    } = this.chatData;
    const text = initialText || '';

    return {
      text,
      placeholder,
      emojiPickerVisible: false,
      lastDraft: text,
      typingTimeouts: {},
    }
  },

  methods: {
    selectEmoji(emoji) {
      // this.emojiPickerVisible = false;
      this.text += emoji.data;
    },

    saveDraft() {
      const text = this.text.trim()
      if (this.lastDraft === text) {
        return; // Don't save if already saved with same text
      }

      const { postDraftEndpoint, csrfToken } = this.chatData;
      sendMessage(text, postDraftEndpoint, csrfToken);
      this.lastDraft = text; // Remember we saved this version
    },

    inputHandler(e) {
      if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey) {
        // Enter key was pressed without shift or ctrl, send message
        e.preventDefault();
        this.onEnter();
      } else {
        // Otherwise just update the draft (throttled)
        this.throttledSaveDraft()
      }
    },

    onEnter() {
      if (this.text.trim().length < 1) {
        return; // prevent submitting empty
      }
      const message = this.text;
      this.text = '';
      const { postEndpoint, csrfToken } = this.chatData;
      sendMessage(message, postEndpoint, csrfToken);
    },

    onMessageReceived (data) {
      const shouldScrollAfterAppend = isScrolledToBottom();

      appendMessage(data.rendered);
      attachChatMessageEvents(this);

      if (shouldScrollAfterAppend) scrollToBottom();
      
      // And mark the channel as having been caught up
      this.throttledMarkChannelAsRead();
    },

    onTyping (data) {
      const {user, userId} = data;
      setIsTyping(user, userId);
    },

    markChannelAsRead() {
      // Mark this channel as having been read
      const { markAsViewedEndpoint, csrfToken } = this.chatData;
      sendMessage('', markAsViewedEndpoint, csrfToken);
    },

    appendToMessage(text) {
      const textToAdd = text + ' ';
      
      if (!this.text.endsWith(textToAdd)) {
        this.text += textToAdd;
      }
    },
  }
}
</script>

<style>
body {
  --livechat-mb-margins: 20px;
  --emoji-font-family: "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, serif, LastResort;
}

#EmojiPicker {
  /*--ep-color-bg: #f0f0f0;*/
  --ep-color-bg: white;
  --ep-color-sbg: #f6f6f6;
  /*--ep-color-sbg: #f6f6f6;*/
  --ep-color-border: #e4e4e4;
  --ep-color-text: #4a4a4a;
  --ep-color-active: var(--theme-primary-color) !important;
}

.MessageBox-structure {
  position: relative;
  /*
    height specification makes sure the box doesn't get tall
    enough to cause a scrollbar to show up on the body
  */
  height: 73px;
}

.MessageBox-toolbar {
  position: absolute;
  /*background: whiteSmoke;*/
  padding: 1px;
  bottom: 13px;
  right: calc(var(--livechat-mb-margins) + 5px);
}

#EmojiPicker .emoji {
  font-family: var(--emoji-font-family);
  border: none;
}

.MessageBox-textarea {
  width: calc(100% - var(--livechat-mb-margins) * 2);
  height: 100%;
  resize: none;
  border: 1px solid #dedede;
  box-sizing: border-box;
  outline: none;
  /*font-family: var(--emoji-font-family);*/
  border-radius: 5px;
  margin-left: var(--livechat-mb-margins);
  margin-right:  var(--livechat-mb-margins);
  margin-top: 5px;
  padding: 8px;
  padding-right: 40px; /* Provide space for emoji picker */
}

.MessageBox-emojiPickerWrapper {
  position: fixed;
  bottom: 80px;
  right: 0px;
}

.MessageBox-button input {
  display: none;
}

.MessageBox-buttonEmoji {
  /*font-family: var(--emoji-font-family);*/
  font-size: 2rem;
}

.MessageBox-button {
  border: 1px solid #dedede;
  font-size: 2rem;
  margin: 0;
  padding: 0;
  border-radius: 8px;
  color: #dedede;
}

.MessageBox-button input:not(:checked) ~ .MessageBox-buttonEmoji::after {
  content: '\263A';
}

.MessageBox-button input:checked ~ .MessageBox-buttonEmoji::after {
  content: '\1F61B';
  color: var(--theme-primary-color) !important;
}



@media (max-width: 768px) {
  body {
    --livechat-mb-margins: 2px;
  }
  .MessageBox-textarea {
    border: none;
  }
}

body.VideoChat--videoEnabled {
  --livechat-mb-margins: 2px;
}
body.VideoChat--videoEnabled .MessageBox-textarea {
  border: none;
}

/*
@media (min-width: 768px) {
  .MessageBox-textarea {
    border-radius: 5px;
    margin-left: var(--livechat-mb-margins);
    margin-right:  var(--livechat-mb-margins);
    margin-top: 5px;
    padding: 8px;
  }
}
@media (max-width: 768px) {
  .MessageBox-toolbar {
    display: none;
  }
  .MessageBox-textarea {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
  }
}
*/
</style>
