<template>
  <div class="JitsiVideo">
    <div class="JitsiVideo-wrapperDiv" ref="jitsiDiv">
    </div>
  </div>
</template>

<script>
/*eslint no-unused-vars: "warn"*/

export default {
  name: 'ListItem',
  data() {
    return {
    }
  },
  props: {
    roomName: { type: String },
    displayName: { type: String },
    avatar: { type: String },
  },
  mounted: function () {
    const domain = 'meet.jit.si';
    const { roomName, displayName, avatar } = this;
    const options = {
      userInfo: {
        avatarUrl: avatar,
        displayName,
      },
      roomName,
      width: "100%",
      height: "100%",
      parentNode: this.$refs.jitsiDiv,
      configOverwrite: {
        startWithAudioMuted: true,
        startWithVideoMuted: true,
        prejoinPageEnabled: false,
      },
      interfaceConfigOverwrite: { 
        //filmStripOnly: true,

        VERTICAL_FILMSTRIP: false,
        APP_NAME: 'LiveSyllabus',
        DISABLE_FOCUS_INDICATOR: true,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DEFAULT_BACKGROUND: '#fafafa',
        SHOW_JITSI_WATERMARK: false,
        SHOW_BRAND_WATERMARK: false,
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        GENERATE_ROOMNAMES_ON_WELCOME_PAGE: false,
        HIDE_INVITE_MORE_HEADER: true,
        ENABLE_DIAL_OUT: false,
        RECENT_LIST_ENABLED: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        MOBILE_APP_PROMO: false,
        TOOLBAR_BUTTONS: [
          'microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
          'fodeviceselection',
        ],
      },
    };

    // eslint-disable-next-line
    this.api = new JitsiMeetExternalAPI(domain, options);
  },
  computed: {
  },
  methods: {
    doSomething() {
    },
  },
}

</script>

<style>
  .JitsiVideo {
    height: 100%;
  }
  .JitsiVideo-wrapperDiv {
    height: 100%;
  }
</style>
