/*
  Brings in all components and sets them up using the Mounting Utils (which
  mounts based on ls- and lsfetch- prefixed DOM attributes)
*/
import Vue from 'vue'
import LiveMode from './livemode/LiveMode.vue'
import LiveChat from './livechat/LiveChat.vue'
import VideoChat from './livechat/VideoChat.vue'
import MessageBox from './livechat/MessageBox.vue'
import CourseAdjustCalendar from './courseadjustcalendar/CourseAdjustCalendar.vue'
import MultiChoice from './multichoice/MultiChoice.vue'
// import FormBuilder from './formbuilder/FormBuilder.vue'

import {setupAllComponents, setupFetchComponents} from './lib/livesyllabusMountingUtils.js';

// Setup "full-calendar" component
import FullCalendar from 'vue-full-calendar';
import 'fullcalendar/dist/fullcalendar.min.css';
Vue.use(FullCalendar);

// Setup "beautiful-chat" component
import Chat from 'vue-beautiful-chat'
Vue.use(Chat)

Vue.config.productionTip = false

// These are the LiveSyllabus components that get mounted onto the DOM
const topLevelComponents = {
  LiveMode,
  LiveChat,
  VideoChat,
  LiveChatMessage: MessageBox,
  //FormBuilder, // no longer going to do via JS, for now
  CourseAdjustCalendar,
  MultiChoice,
};

function main() {
  if (!window.LS) {
    window.LS = {};
  }
  window.LS.setupAllComponents = setupAllComponents;
  window.LS.setupFetchComponents = setupFetchComponents;
  window.LS.setupAll = main;

  setupAllComponents(topLevelComponents);
  setupFetchComponents(topLevelComponents, () => {
    // Set up components again because we fetched more data
    setupAllComponents(topLevelComponents);
  });
}

main();
