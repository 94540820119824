/*eslint no-unused-vars: "warn"*/

import {addDays, startOfDay, differenceInDays} from 'date-fns';

export function inRange(ev, {start, end}) {
  if (!start || !end) {
    return false;
  }
  return ev.start > start && ev.start < end;
}

function subtractObjects(a, b) {
  const dupe = {...a};
  for (const key of Object.keys(b)) {
    delete dupe[key];
  }
  return dupe;
}

export function nudge(events, {days}, filter) {
  return events.map(ev => !filter(ev) ? ev : {
      ...ev,
      start: addDays(ev.start, days),
      end: ev.end ? addDays(ev.end, days) : undefined,
    });
}

export function getEarliest(events, key='start') {
  if (events.length < 1) {
    return null;
  }
  const dates = events.map(ev => ev[key]).filter(d => d);
  dates.sort((a, b) => a - b);
  return dates[0];
}

export function makeStartAt(events, newStartDate) {
  if (events.length < 1) {
    return events;
  }
  const courseStart = startOfDay(getEarliest(events));
  const diff = differenceInDays(startOfDay(newStartDate), courseStart);
  return nudge(events, {days: diff}, () => true);
}


export function getSelected(events, range, invertRange = false) {
  const func = invertRange ?
    (ev => inRange(ev, range)) : (ev => !inRange(ev, range));
  return events.filter(func);
}


export function setEventsInRange(events, range, extraData) {
  return events.map(ev => inRange(ev, range)
    ? {...ev, ...extraData}
    : subtractObjects(ev, extraData));
}

