<!--
    <pre>{{stringifiedOptions}}</pre>
    <pre>{{opts}}</pre>
-->
<template>
  <div class="MultiChoice" ref="outerDiv" >
    <input type="hidden" :value="stringifiedOptions" :name="name" />

    <draggable v-model="opts" group="optsGroup" @start="drag=true" @end="drag=false">
      <div class="input-group" v-for="(optionInfo, index) of opts" :key="index">

        <div class="input-group-prepend">
          <div class="input-group-text text-white">
            <i class="fa fa-bars"></i>
          </div>
        </div>

        <div class="input-group-prepend" v-if="shouldShowOption">
          <div class="input-group-text" @click="onOptionClick(index)">
              <i
                class="fa fa-circle"
                v-if="optionInfo.value === correctValue"
              ></i>
              <i
                class="fa fa-circle-o"
                v-if="optionInfo.value !== correctValue"
              ></i>
          </div>
        </div>

        <input
          type="text"
          class="form-control"
          ref="optInput"
          placeholder="Add an option..."
          v-model="optionInfo.value"
          v-on:keydown.enter.prevent="onEnter(index)"
          v-on:keyup.up="onUp(index)"
          v-on:keyup.down="onDown(index)"
        />

        <button class="btn btn-outline-light" type="button">
          <i class="fa fa-remove" alt="Delete option" title="Delete option" @click="onDelete(index)"></i>
        </button>
      </div>
    </draggable>

    <div class="input-group">
      <button class="btn btn-light btn-block" type="button" @click="newOption">
        <i class="fa fa-plus" alt="Add new option" title="Add new option"></i>
      </button>
    </div>

  </div>
</template>

<style>
.MultiChoice {
}
</style>

<script>
import draggable from 'vuedraggable'

export default {
  name: 'MultiChoice',

  props: {
    name: String,
    settings: Object,
    value: String,
  },

  components: {
    draggable,
  },

  data() {
    const valAsString = (this.value || '').trim();
    const split = valAsString.split('\n').map(s => s.trim());
    // Filter out falsy values & map to objects
    const opts = split.filter(v => v).map(value => ({ value }));
    if (opts.length === 0) {
      opts.push({value: ''}); // by default have 1 empty
    }

    let inputType = 'radio';
    if (this.settings.inputType) {
      inputType = this.settings.inputType;
    }

    return { inputType, opts, isMounted: false };
  },

  mounted: function () {
    this.isMounted = true; // force an update, to allow computed props
  },

  computed: {
    stringifiedOptions: function() {
      return this.opts.map(({value}) => value).join('\n').trim();
    },

    correctValue: function () {
      if (!this.isMounted) {
        return null; // not mounted yet
      }
      const input = this.getCorrectInput();
      return (input && input.value) ? input.value.trim() : null;
    },

    shouldShowOption: function () {
      if (!this.isMounted) {
        return null; // not mounted yet
      }
      return !!(this.getCorrectInput());
    },
  },

  methods: {
    getCorrectInput: function () {
      // NOTE: Hacky thing that uses DOM methods
      // NOTE: closest has no IE11 support
      const fieldset = this.$refs.outerDiv.closest('fieldset');
      if (!fieldset) {
        console.log('Not in fieldset');
        return null;
      }
      const correctInput = fieldset.querySelector('input[name$=correct_value]');
      if (!correctInput || correctInput.offsetParent === null){
        // https://stackoverflow.com/questions/19669786/
        console.log('Not in correct_value form');
        return null;
      }
      return correctInput;
    },

    onDown: function (index) {
      const input = this.$refs.optInput[index + 1];
      if (input) {
        input.focus();
      }
    },

    onUp: function (index) {
      const input = this.$refs.optInput[index - 1];
      if (input) {
        input.focus();
      }
    },

    onEnter: function (index) {
      this.opts.splice(index + 1, 0, {value: ''});
      setTimeout(() => {
        this.onDown(index);
      });
    },

    onDelete: function (index) {
      this.opts.splice(index, 1);
    },

    newOption: function () {
      this.opts.push({value: ''});
    },

    onOptionClick: function (index) {
      const {value} = this.opts[index];
      const correctInput = this.getCorrectInput();
      if (correctInput && value) {
        correctInput.value = value.trim();
        this.isMounted++; // force update
      }
    },
  },
}

</script>
