<template>
  <div>
    <ul class="LiveMode-list">
      <ListItem
        v-for="item in items"
        :click="selectItem"
        :isItemSelected="selectedItem.id === item.id"
        :item="item"
        :key="item.id + ':' + String(selectedItem.id === item.id)">
      </ListItem>
    </ul>
  </div>
</template>

<script>
import ListItem from './ListItem.vue'

export default {
  name: 'List',
  props: {
    items: Array,
    onSelected: Function,
    selectedItem: Object,
  },
  components: {
    ListItem
  },
  methods: {
    selectItem(item) {
      this.onSelected(item);
    },
  },
}
</script>

<style>
.LiveMode-list {
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 768px) {
  .LiveMode-list {
    padding-right: 2px;
    padding-left: 2px;
    margin: 2px;
    margin-right: 0;
    margin-left: 0;
    box-sizing: border-box;
    height: auto;
    max-height: calc(100vh - var(--navbar-height) - var(--narrow-sidebar-breadcrumbs-height));
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .LiveMode-list {
    height: auto;
    max-height: auto;
  }
}
</style>
