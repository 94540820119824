<template>
  <div 
      class="LiveChat"
      :class="{ 'LiveChat--fullMode': isFullMode, 'LiveChat--cornerMode': !isFullMode }"
      >
    <div class="LiveChat-justChat" v-if="!showVideoPane">
      <ChatBox :chatData="chatInfo" :onMessageListUpdated="onMessageListUpdated">
      </ChatBox>
    </div>
    <div class="LiveChat-videoLayout" v-if="showVideoPane">
      <JitsiVideo :roomName="videoInfo.roomName">
      </JitsiVideo>
      <ChatBox :chatData="chatInfo" :onMessageListUpdated="onMessageListUpdated">
      </ChatBox>
    </div>
  </div>
</template>

<script>
/*

      <multipane layout="vertical">
        <JitsiVideo>
        </JitsiVideo>
        <multipane-resizer></multipane-resizer>
        <ChatBox :chatData="chatInfo">
        </ChatBox>
      </multipane>
import { Multipane, MultipaneResizer } from 'vue-multipane';
    Multipane,
    MultipaneResizer
*/
import JitsiVideo from './JitsiVideo.vue';
import ChatBox from './ChatBox.vue';
import {getVideoInfo} from './dataHelpers.js';

export default {
  name: 'LiveChat',
  components: {
    JitsiVideo,
    ChatBox,
  },

  props: {
    chatData: Object,
  },

  data() {
    const {isFullMode} = this.chatData;
    //const {showVideoPane} = this.chatData;
    let showVideoPane = false;
    const videoInfo = getVideoInfo(this.chatData.messageList, this.chatData);
    if (videoInfo) {
      showVideoPane = true;
    }
    return {
      chatInfo: this.chatData,
      isFullMode,
      showVideoPane,
      videoInfo,
    }
  },

  methods: {
    onMessageListUpdated(messageList) {
      const videoInfo = getVideoInfo(messageList, this.chatData);
      if (videoInfo) {
        this.showVideoPane = true;
        this.videoInfo = videoInfo;
      } else {
        this.showVideoPane = false;
        this.videoInfo = null;
      }
    }
  }
}
</script>

<style>
  .LiveChat--fullMode,
  .LiveChat--fullMode > div {
    height: 100%;
    width: 100%;
  }
  .LiveChat-videoLayout {
    display: grid;
    grid-template-columns: 1fr 370px;
  }


/*
.multipane.layout-v .multipane-resizer {
  margin: 0; left: 0;
  width: 10px;
  height: 100vh;
  background-color: var(--theme-primary-color);
}
*/
</style>
