const STOP_TYPING_TIMEOUT = 6 * 1000;

const typingTimeouts = {};


function getLastMessage(elem) {
  const messages = (elem || document).querySelectorAll('.LiveChat-message[data-uid]');
  if (messages && messages.length) {
    return messages[messages.length - 1];
  }
  return null;
}

const CLICK_FOR_TEXT_ATTR = 'data-click-for-text';

export function toggleBodyVideoClass(shouldAdd) {
  const body = document.body;
  if (shouldAdd) {
    body.classList.add('VideoChat--videoEnabled');
  } else {
    body.classList.remove('VideoChat--videoEnabled');
  }
}

function setupMessage(elem, component) {
  // Attach "click" event to cause @ to show
  const children = elem.querySelectorAll('[' + CLICK_FOR_TEXT_ATTR + ']');
  for (const childElem of children) {
    const text = childElem.getAttribute(CLICK_FOR_TEXT_ATTR);
    childElem.addEventListener('click', () => {
      component.appendToMessage(text);
    });
  }
}

export function attachChatMessageEvents(component, parentNode = null) {
  // NOTE: Presently parentNode is unused, probably can make more efficient
  const messageParent = parentNode !== null ? parentNode : (
    document.querySelector('#LiveChat-append').parentNode
  );
  const doneMarker = '-domUtilsEvAtt';
  const selector = `[data-uid]:not(${doneMarker})`;
  const elems = messageParent.querySelectorAll(selector);

  for (const elem of elems) {
    if (elem.classList.contains(doneMarker)) {
      console.log('messed up delete this');
      continue;
    }
    setupMessage(elem, component);
    elem.classList.add(doneMarker);
  }
}

export function appendMessage(html) {
  const previousMessage = getLastMessage();

  const appendSpot = document.querySelector('#LiveChat-append');
  if (lastDateSeparatorIsOld()) addDateSeparator();

  appendSpot.innerHTML += html;

  const newMessage = getLastMessage(appendSpot);
  if (!newMessage) {
    //console.log('NOTHING TOSEE HERE!')
    return null;
  }

  // Check if the last message is by the same person as the previous last message
  const uid1 = newMessage.getAttribute('data-uid');
  clearIsTyping(uid1); // clear the typing indicator since they just sent a message
  const uid2 = previousMessage.getAttribute('data-uid');
  // let goodClass = 'LiveChat--uinfo';
  let badClass = 'LiveChat--dinfo';
  if (uid1 === uid2) {
    // goodClass = 'LiveChat--dinfo';
    badClass = 'LiveChat--uinfo';
    //console.log('equal')
  } else {
    //console.log('not equal')
  }
  const badThings = newMessage.querySelectorAll('.' + badClass);
  for (const badElement of badThings) {
    badElement.remove();
  }

  return uid1;
}

function lastDateSeparatorIsOld() {
  const today = new Date();
  const previousDate = getLastSeparatedDate();

  if (!previousDate) return true
  if (previousDate.getYear() != today.getYear()) return true
  if (previousDate.getMonth() != today.getMonth()) return true
  if (previousDate.getDate() != today.getDate()) return true

  return false
}

function addDateSeparator() {
  const appendSpot = document.querySelector('#LiveChat-append');
  const today = new Date();
  // The `month + 1` part is correcting for zero-indexed js months
  appendSpot.innerHTML += `<div class="LiveChat-dateSeperator"
            data-day="${today.getDate()}"
            data-month="${today.getMonth() + 1}"
            data-year="${today.getFullYear()}">
            <div class="LiveChat-dateSeperatorLabel">
                ${getTodaysDateString()}
            </div>
        </div>`;
}

function getTodaysDateString() {
  const locale = Intl.DateTimeFormat().resolvedOptions().locale;
  const today = new Date();
  const dateString = today.toLocaleString(locale, { month: 'long', day: 'numeric' });

  if (locale == "en-US") {
    const suffix = englishOrdinalSuffix(today);
    return dateString + suffix;
  } else {
    return dateString;
  }
}

function englishOrdinalSuffix(date) {
  if (date.getDate() % 10 == 1 && date.getDate() != 11) return 'st';
  if (date.getDate() % 10 == 2 && date.getDate() != 12) return 'nd';
  if (date.getDate() % 10 == 3 && date.getDate() != 13) return 'rd';
  return 'th';
}

function getLastSeparatedDate() {
  const separators = document.querySelectorAll('.LiveChat-dateSeperator');
  const lastSeparator = separators[separators.length - 1];

  if (lastSeparator) {
    const { day, month, year } = lastSeparator.dataset;
    const date = new Date();
    date.setDate(day);
    // JavaScript stores months as zero-indexed
    date.setMonth(month - 1);
    date.setFullYear(year);
    return date;
  } else {
    return null;
  }
}

export function isScrolledToBottom() {
  const messagesBox = document.querySelector('.LiveChat-structureMessages');
  const inputBoxHeightPlusSomeLeeway = 100;

  return messagesBox.scrollHeight - messagesBox.scrollTop - messagesBox.clientHeight < inputBoxHeightPlusSomeLeeway;
}

export function scrollToBottom() {
  // Scroll to bottom
  document.getElementById('LiveChat-messageListBottom').scrollIntoView(false);
}

export function setIsTyping(user, userId) {
  const elem = document.querySelector('#LiveChat-isTyping');
  const id = `LiveChat-isTyping-${userId}-typing`;
  const existing = document.getElementById(id);
  if (!existing) {
    const text = `${user} is typing...`;
    const className = 'LiveChat-typingIndicator';
    const html = `<span class="${className}" id="${id}">${text}</span>`;
    elem.innerHTML += html;
  }

  if (typingTimeouts[userId]) {
    clearTimeout(typingTimeouts[userId]);
    typingTimeouts[userId] = null;
  }

  setTimeout(() => clearIsTyping(userId), STOP_TYPING_TIMEOUT);
}

function clearIsTyping(userId) {
  const id = `LiveChat-isTyping-${userId}-typing`;
  const elem = document.getElementById(id);
  if (elem) {
    elem.remove();
  }
  if (typingTimeouts[userId]) {
    clearTimeout(typingTimeouts[userId]);
  }
  typingTimeouts[userId] = null;
}
