<template>
  <div class="LiveMode-videoPlayer">
    <div v-once>
      <div id="embedded_jw_player"></div>
    </div>
    <div :class="contentClass" v-html="videoInfo.htmlContent"></div>
  </div>
</template>

<script>
//import bookmarkSvg  from './bookmark.svg';
import {setupVideoCompletionAPI} from './apiHelpers.js';

export default {
  name: 'List',
  props: {
    videoInfo: Object,
  },
  computed: {
    contentClass: function () {
      if (this.videoInfo.content) {
        if (this.videoInfo.htmlContent) {
          return 'LiveMode-contentShower LiveMode--hasContent';
        } else {
          return 'LiveMode-contentShower';
        }
      } else {
        return 'LiveMode-contentShower LiveMode--onlyContent';
      }
    },
  },

  mounted() {
    // console.log('this is video info', this.videoInfo.content);
    if (!this.videoInfo.content) {
      // console.log('No video specified:', this.videoInfo);
      return;
    }
    const videoName = this.videoInfo.content.split('-')[0];
    const playlist = `https://cdn.jwplayer.com/v2/media/${videoName}`;

    // eslint-disable-next-line
    this.jwpl = jwplayer('embedded_jw_player').setup({playlist});
    // (Linting interfaces with JWPlayer's non-Vue interface to setup the player)

    this.jwpl.on('play', info => {
      console.log('Playback:', info);
      //console.log(this.jwpl.getDuration());
    });

    const {completion} = this.videoInfo;
    setupVideoCompletionAPI(this.jwpl, videoName, completion);

    /*
    // Later, using cues we could make a simple bookmark system:
    this.jwpl.addButton(
      bookmarkSvg,
      'Create a bookmark at this playback location',
      () => {
        console.log('lets bookmark!', this.jwpl.getPosition());
      },
      'bookmark_resume'
    );
    */
  },
}
</script>

<style>
.LiveMode-contentShower {
  background: white;
}


@media screen and (min-width: 768px) {
  .LiveMode--hasContent,
  .LiveMode--onlyContent {
    border-radius: 20px;
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.15);
    margin: 10px;
    padding: 10px;
  }

  .LiveMode--onlyContent {
    margin: 50px;
    padding: 50px;
    font-size: 1.2rem;
    min-height: 70%;
  }
}
</style>
