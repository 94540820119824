<template>
  <div class="CourseAdjustCalendar">
    <input
      type="hidden"
      :name="name"
      :value="jsonStringifiedData"
    />

    <div class="CourseAdjustCalendar-leftActions">
      <div class="btn btn-dark" @click="resetToToday">
        Move to today
      </div>

      <label v-if="rangeSelected.start">
        Modify selected items:
      </label>
      <label class="m-2 ml-5">Day</label>
      <div class="btn btn-dark" @click="nudgeOneDayBackward">
        &laquo;
      </div>
      <div class="btn btn-dark" @click="nudgeOneDayForward">
        &raquo;
      </div>

      <label class="m-2 ml-5">Week</label>
      <div class="btn btn-dark" @click="nudgeOneWeekBackward">
        &and;
      </div>
      <div class="btn btn-dark" @click="nudgeOneWeekForward">
        &or;
      </div>

    </div>

    <full-calendar
      :config="config"
      v-bind:events="events"
      ref="calendar"
      @event-drop="eventDrop"
      @event-selected="eventSelected"
      @select="rangeSelected"
    />
  </div>
</template>

<script>
import { parseISO, startOfDay, endOfDay } from 'date-fns';
//import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz';

import {
  nudge,
  makeStartAt,
  getEarliest,
  setEventsInRange,
} from '../lib/eventsUtils';

// NOTE: Background is tricky, can simulate with black text and white BG

/*eslint no-unused-vars: "warn"*/

export default {
  name: 'CourseAdjustCalendar',
  props: {
    eventData: Object,
    name: String,
  },

  components: {
  },

  computed: {
    jsonStringifiedData: function () {
      // Only stringify start and ID fields
      //console.log('restringifying!');
      const cleanedEvents = [];
      for (const {start, id, rendering} of this.events) {
        cleanedEvents.push({start, id});
      }
      return JSON.stringify(cleanedEvents);
    },
  },

  data () {
    // console.log('this is course data:', this.eventData);
    const rawEvents = this.eventData.events;

    // Convert to native dats
    const events = rawEvents.map(ev => ({
      ...ev,
      start: parseISO(ev.start),
      end: ev.end ? parseISO(ev.end) : undefined,
    }));
    const earliest = getEarliest(events);
    const rangeSelected = this.rangeSelected.bind(this);

    return {
      events,
      selectedEvent: {},
      selectedRange: {},
      config: {
        themeSystem: 'bootstrap4',
        sync: true,
        /*selectable: false,
        selectMinDistance: 2,*/
        selectable: true,
        unselectAuto: true,
        unselectCancel: '.btn',
        defaultView: 'month',
        defaultDate: earliest,
        /*height: 'calc(100vh - 300px)',*/
        height: 600, /* Doesn't support CSS calc :( */
        eventDurationEditable: false,
        header: {
          left:   '',
          center: '',
          right:  'title today prev,next'
        },
        'select': rangeSelected,
        'unselect': rangeSelected,
      },
    }
  },

  mounted: function () {
    // console.log('mounted:', this.eventData);
    // Hook up arrow keys to nudge
    document.onkeydown = ev => {
      ev = ev || window.event;
      if (ev.keyCode == '38') {
        this.nudgeOneWeekBackward();
      }
      else if (ev.keyCode == '40') {
        this.nudgeOneWeekForward();
      }
      else if (ev.keyCode == '37') {
        this.nudgeOneDayBackward();
      }
      else if (ev.keyCode == '39') {
        this.nudgeOneDayForward();
      }
    }
  },

  methods: {
    eventDrop: function(newEv) {
      // Update the event that was dropped with the new data
      const start = newEv.start.local().toDate();
      const end = newEv.end ? newEv.end.local().toDate() : undefined;
      this.events = this.events.map(ev => ev.id === newEv.id
        ? {...ev, start, end} : ev);
    },

    eventSelected(ev) {
      // NOTE: currently this is unused
      this.selectedEvent = ev;
      // console.log('ev selected', ev);
    },
    rangeSelected(start, end, jsEvent) {
      if (!jsEvent) {
        // Deselect
        this.rangeSelected = {};
      } else {
        // NOTE
        this.rangeSelected = {
          start: startOfDay(start.local().toDate()),
          end: endOfDay(end.local().toDate()),
        };
      }

      const style = {
        borderColor: 'black',
        backgroundColor: 'black',
        groupSelected: true,
      };
      this.events = setEventsInRange(this.events, this.rangeSelected, style);
      // console.log('range selected', this.rangeSelected);
    },

    _getFilter: function() {
      return this.rangeSelected.start ? (ev => ev.groupSelected) : () => true;
    },

    nudgeOneDayForward: function() {
      this.events = nudge(this.events, {days: 1}, this._getFilter());
    },
    nudgeOneDayBackward: function() {
      this.events = nudge(this.events, {days: -1}, this._getFilter());
    },


    nudgeOneWeekForward: function() {
      this.events = nudge(this.events, {days: 7}, this._getFilter());
    },
    nudgeOneWeekBackward: function() {
      this.events = nudge(this.events, {days: -7}, this._getFilter());
    },

    resetToToday: function() {
      this.events = makeStartAt(this.events, new Date());
      this.$refs.calendar.fireMethod('today')
    },
  }
}

</script>

<style>
.CourseAdjustCalendar {
}

.CourseAdjustCalendar-leftActions {
  float: left;
}

</style>
