export function sendMessage(message, postEndpoint, csrfToken) {
  const data = new FormData();
  data.append('text', message);
  data.append('csrfmiddlewaretoken', csrfToken);
  const fetchOptions = {
    method: 'POST',
    body: data,
    credentials: 'same-origin',
  };
  fetch(postEndpoint, fetchOptions)
    .then(() => {
      // console.log('sent message!', response);
    })
    .catch(e => {
      console.error('ERROR:', e);
    });
}

export function getMoreMessages(getEndpoint, component) {
  // DEAD CODE?
  fetch(getEndpoint)
    .then(response => response.json())
    .then(data => {
      // console.log('got more!');
      const {getMoreMessagesEndpoint, messageList} = data;
      if (!getMoreMessagesEndpoint, messageList) {
        console.error('Invalid response:', data);
        return;
      }
      component.messageList = messageList.concat(component.messageList);
      component.getMoreMessagesEndpoint = getMoreMessagesEndpoint;
    })
    .catch(e => {
      console.error('ERROR:', e);
    });
}

export function setupPusher(component) {
  const { channelName } = component.chatData;
  const pusher = window.globalPusher;
  const channel = pusher.subscribe(channelName);

  channel.bind('message', data => {
    component.onMessageReceived(data);
  });

  channel.bind('typing', data => {
    component.onTyping(data);
  });
}


export function setupPusherVideo(component) {
  const { channelName } = component.videoData;
  const pusher = window.globalPusher;
  const channel = pusher.subscribe(channelName);
  channel.bind('video', data => {
    component.onVideo(data);
  });
}

export function onTyping() {
  // Later add typing indicator via:
  // https://pusher.com/docs/channels/using_channels/events#triggering-client-events
  //channel.
}

