const POST_INTERVAL = 10; // 10 seconds
//const POST_INTERVAL = 1;

function getPersonalNoteAPIEndpoint() {
  // NOTE: Terrible hack, should get removed when this is rewritten away from vue
  const node = document.querySelector('[ls-personalnote-api-endpoint]');
  if (!node) {
    return;
  } else {
    return node.getAttribute('ls-personalnote-api-endpoint');
  }
}

export function setupVideoCompletionAPI(jwpl, videoName, completion) {
  const postEndpoint = getPersonalNoteAPIEndpoint();
  if (!postEndpoint) {
    return false;
  }

  // Figure out the last second of the video watched
  let lastWatched = 0;
  if (completion && completion.lastWatched) {
    lastWatched = completion.lastWatched;
  }

  // Show the "cue" in the scrubber
  if (lastWatched > 1) {
    const text = '(Last Watched) \n' + (completion.lastWatchedDate || '');
    jwpl.setCues([{text, begin: lastWatched}]);
  }
  //console.log('this is lastWatched', lastWatched);

  // (Defensive) Using global / window variable to prevent accidental "timeout
  // leak" between multiple players on the same page. Since this isn't
  // "mission-critical", it's better to just clear any timeout.
  if (window.lsVideoPlayerTimeout) {
    clearTimeout(window.lsVideoPlayerTimeout);
  }

  // Create a timeout that checks to see if we've progressed enough since last
  // time (10 seconds) and then update the backend if so
  let lastPosted = lastWatched;
  let hasCleared = false;
  window.lsVideoPlayerTimeout = window.setInterval(() => {
    const time = jwpl.getPosition();
    //console.log('checking...', time, lastPosted, lastPosted + POST_INTERVAL);
    if (time < (lastPosted + POST_INTERVAL)) {
      return;
    }
    //console.log('past post interval');
    if (!hasCleared) {
      jwpl.setCues([]);
      hasCleared = true; // prevent extra sets
    }
    lastPosted = time;

    const postData = {
      [videoName]: {
        lastWatched: time,
        lastWatchedDate: (new Date()).toDateString(),
      },
    };
    postVideoCompletionInfo(postEndpoint, postData);
  }, 1000);
}


function postVideoCompletionInfo(postEndpoint, postData) {
  const data = new FormData();
  const jsonified = JSON.stringify(postData);
  //console.log('this is jsonified', jsonified);
  data.append('video_completion', jsonified);
  data.append('video_event_log', '[]');
  const fetchOptions = {
    method: 'POST',
    body: data,
    credentials: 'same-origin',
  };
  fetch(postEndpoint, fetchOptions)
    .then(() => {
      //console.log('sent message!', response);
    })
    .catch(e => {
      console.error('ERROR:', e);
    });
}

