<template>
  <div class="VideoChat">
    <JitsiVideo
      v-if="isLive"
      :roomName="room"
      :displayName="name"
      :avatar="imageUrl">
    </JitsiVideo>
  </div>
</template>

<script>
/*eslint no-unused-vars: "warn"*/
import JitsiVideo from './JitsiVideo.vue';
import {setupPusherVideo} from './apiHelpers.js';
import {toggleBodyVideoClass} from './domUtils.js';

export default {
  name: 'VideoChat',
  components: {
    JitsiVideo,
  },

  mounted() {
    setupPusherVideo(this);
  },

  props: {
    videoData: Object,
  },

  data() {
    const {room, isLive} = this.videoData;
    const {name, imageUrl} = this.videoData.userInfo;
    toggleBodyVideoClass(isLive);
    return {
      room,
      isLive,
      name,
      imageUrl,
    };
  },

  methods: {
    onVideo(data) {
      if (data.isLive) {
        this.isLive = true;
        if (data.room) {
          this.room = data.room;
        }
      } else {
        this.isLive = false;
      }
      toggleBodyVideoClass(this.isLive);
    },
  }
}
</script>


<style>
  .VideoChat,
  .VideoChat > div {
    height: 100%;
  }
</style>
