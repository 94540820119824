<template>
  <div class="LiveMode">
    <VideoPlayer
        v-bind:videoInfo="selectedVideo"
        :key="selectedVideo.content" />
    <List
        :items="items"
        :onSelected="onSelected"
        :selectedItem="selectedVideo"
        class="list" />
  </div>
</template>

<script>
import List from './List.vue';
import VideoPlayer from './VideoPlayer.vue';

export default {
  name: 'LiveMode',
  props: {
    videoData: Array,
  },
  components: {
    List,
    VideoPlayer,
  },
  data() {
    //console.log('this is video data', this.videoData);
    const selectedVideo = this.videoData[0] || {};
    return {
      items: this.videoData,
      selectedVideo,
    }
  },
  mounted: function () {
    //console.log('mounted:', this.videoData);
  },
  methods: {
    onSelected(item) {
      this.selectedVideo = item;
    }
  }
}

</script>

<style>
.LiveMode {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: auto;
  background: #ededed;
  box-shadow: 0 0 4px inset rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .LiveMode {
    display: block;
  }
}
</style>
