<template>
  <div :class="className">
    <div class="LiveMode-listItem--clickable" @click="selectLecture" v-if="isClickable">
      <img class="LiveMode-listItem-image" v-if="imageIconURL" :src="imageIconURL" />
      <div class="LiveMode-listItem-content" v-html="item.title">
      </div>
    </div>
    <div class="LiveMode-listItem--unclickable" v-if="isNotClickable">
      <div class="LiveMode-listItem-content" v-html="item.title"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ListItem',
  data() {
    return {
      activeItem: ''
    }
  },
  props: {
    click: Function,
    isItemSelected: Boolean,
    item: {
        type: Object,
        value: '',
    }
  },
  computed: {
    className: function () {
      if (this.isItemSelected) {
        return 'LiveMode-listItem LiveMode-listItem--active';
      } else {
        return 'LiveMode-listItem LiveMode-listItem--inactive';
      }
    },
    isClickable: function () {
      return this.item && (this.item.content || this.item.htmlContent);
    },
    isNotClickable: function () {
      return !(this.isClickable);
    },
    imageIconURL: function () {
      if (!this.item || !this.item.content) {
        return null;
      }
      const videoName = this.item.content.split('-')[0];
      return `https://cdn.jwplayer.com/v2/media/${videoName}/poster.jpg`;
    },
  },
  methods: {
    selectLecture() {
      this.activeItem = this.item;
      this.click(this.item);
    },
  },
}

</script>

<style>
.LiveMode-listItem {
  color: black;
  user-select: none;
}

.LiveMode-listItem > div {
  display: flex;
}

.LiveMode-listItem:last-child > div {
  margin-bottom: 3px;
}

.LiveMode-listItem:first-child > div {
  margin-top: 3px;
}

.LiveMode-listItem--clickable {
  cursor: pointer;
  height: 60px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  transition:
    box-shadow 0.3s,
    background, 0.3s;
}

.LiveMode-listItem--unclickable {
  cursor: default;
  height: 40px;
}

.LiveMode-listItem-content {
  margin: 0;
  margin-left: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LiveMode-listItem--active > .LiveMode-listItem--clickable {
  background-color: var(--theme-primary-color);
  color: white;
  box-shadow: 4px 4px 8px inset rgba(255, 255, 255, 0.4);
}

.LiveMode-listItem--inactive > .LiveMode-listItem--clickable  {
  color: black;
  background-color: white;
}

.LiveMode-listItem--inactive > .LiveMode-listItem--clickable:hover  {
  box-shadow: 0 0 4px var(--theme-primary-color);
  z-index: 1;
  position: relative;
}

.LiveMode-listItem-image {
  opacity: 0.4;
  height: 60px;
}

@media screen and (min-width: 768px) {
  .LiveMode-listItem-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .LiveMode-listItem:hover .LiveMode-listItem-content {
    white-space: break-spaces;
  }

  .LiveMode-listItem:hover h4 {
    font-size: 18px;
    margin-bottom: 0;
  }
}

</style>
