<template>
  <div 
      class="ChatBox"
      :class="{ 'ChatBox--fullMode': isFullMode, 'ChatBox--cornerMode': !isFullMode }"
      >
    <beautiful-chat
      :participants="participants"
      :placeholder="placeholder"
      :title="title"
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :icons="icons"
      :open="openChat"
      :showEmoji="true"
      :showFile="false"
      :showEdition="false"
      :showDeletion="false"
      :showTypingIndicator="showTypingIndicator"
      :showLauncher="showLauncher"
      :showCloseButton="showCloseButton"
      :colors="colors"
      :alwaysScrollToBottom="alwaysScrollToBottom"
      :messageStyling="messageStyling"
      @onType="handleOnType"
      @edit="editMessage">
    </beautiful-chat>
  </div>
</template>

<script>
/*eslint no-unused-vars: "warn"*/
import {sendMessage, getMoreMessages, setupPusher} from './apiHelpers.js';
import {getInitialData} from './dataHelpers.js';

export default {
  name: 'ChatBox',

  props: {
    chatData: Object,
    onMessageListUpdated: Function,
  },

  mounted() {
    setupPusher(this);
  },

  data() {
    return getInitialData(this.chatData);
  },

  methods: {
    sendMessage (text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'support', type: 'text', data: { text } })
      }
    },

    onMessageWasSent (message) {
      // called when the user sends a message
      //this.messageList = [ ...this.messageList, message ];
      this.onMessageReceived(message);
      const { text } = message.data;
      const { postEndpoint, csrfToken } = this.chatData;
      sendMessage(text, postEndpoint, csrfToken);
    },

    onMessageReceived (data) {
      this.messageList = [ ...this.messageList, data ];
      this.onMessageListUpdated(this.messageList);
    },

    openChat () {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat () {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop () {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
      const { getMoreMessagesEndpoint } = this;
      if (getMoreMessagesEndpoint) {
        getMoreMessages(getMoreMessagesEndpoint, this);
      }
    },
    handleOnType () {
      //console.log('Emit typing event')
    },
    editMessage(message){
      const m = this.messageList.find(m => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    },
  }
}
</script>

<style>
.ChatBox--cornerMode img.sc-open-icon {
  height: 90px !important;
  width: 90px !important;
  right: 10px !important;
  bottom: 11px !important;
}
.ChatBox--cornerMode .sc-launcher{
  /*opacity: 0.5;*/
  /*background-color: lightgray !important;*/
  /*box-shadow: 0 0 27px 1.5px rgba(0,0,0,.1);*/
}

.ChatBox--cornerMode .sc-launcher:hover{
  opacity: 1;
}

.ChatBox .sc-chat-window.opened,
.ChatBox .sc-header {
  border-radius: 0 !important;
}

.ChatBox .sc-message--text,
.ChatBox .sc-header > * {
  border-radius: 0 !important;
}

.ChatBox--cornerMode .sc-header {
  padding: 0;
  height: 40px !important;
  min-height: 40px !important;
}

.ChatBox--cornerMode .sc-header--title {
  font-size: 18px !important;
}

.ChatBox--fullMode .sc-chat-window {
  max-height: calc(100vh - 160px);
}

.ChatBox--fullMode .sc-chat-window {
  width: 100% !important;
  height: 100% !important;
  max-height: 100% !important;
  position: static !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 !important;
  animation-duration: 0s;
}

.ChatBox--fullMode,
.ChatBox--fullMode > div {
  height: 100%;
}

.ChatBox--fullMode .sc-header--close-button {
  display: none;
}

.ChatBox--fullMode .sc-message {
  margin: 0 !important;
  width: 100%;
}
.ChatBox--fullMode .sc-message--text {
  font-size: 16px !important;
}
.ChatBox--fullMode .sc-user-input--text {
  width: calc(100% - 80px);
}
.ChatBox--fullMode .sc-message--content {
  justify-content: flex-start !important;
  width: 100% !important;
}

.ChatBox--fullMode .sc-message--content.received {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
}

.ChatBox--fullMode .sc-message--content.received .sc-message--text {
  background: none !important;
  border-bottom: 1px solid #eee;
}

.sc-message-list {
  padding: 0 !important;
}
</style>
