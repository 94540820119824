// DEAD CODE (from old chat version)

import CloseIcon from 'vue-beautiful-chat/src/assets/close-icon.png'
import OpenIcon from 'vue-beautiful-chat/src/assets/logo-no-bg.svg'
import FileIcon from 'vue-beautiful-chat/src/assets/file.svg'
import CloseIconSvg from 'vue-beautiful-chat/src/assets/close.svg'

import colorscheme from './colorscheme.js';

export function getVideoInfo(messageList, chatData) {
  if (!chatData.isFullMode) {
    return false;
  }

  let isInVideo = false;
  for (const message of messageList) {
    let { text } = message.data;
    text = text.trim().toLowerCase();
    if (text === '\\video start') {
      console.log('in video!');
      isInVideo = true;
    }
    if (text === '\\video stop') {
      isInVideo = false;
    }
  }
  if (!isInVideo) {
      console.log('not in video!');
    return false;
  }
  console.log('in video!');

  const { channelName } = chatData;
  return {
    roomName: 'ls-' + channelName,
  };
}

export function getInitialData(chatData) {
  console.log('this is data:', chatData);

  const {
    messageList,
    titleImageUrl,
    participants,
    newMessagesCount,
    isFullMode,
    getMoreMessagesEndpoint,
    title,
    placeholder,
    extraMessageContext,
  } = chatData;

  const {
    iconOpen,
    iconClose,
    iconCloseSvg,
    iconFile,
  } = {
    iconOpen: OpenIcon,
    iconClose: CloseIcon,
    iconCloseSvg: CloseIconSvg,
    iconFile: FileIcon,
    ...chatData,
  };

  let showLauncher = true;
  let showCloseButton = true;
  let isChatOpen = chatData.isChatOpen || false;
  if (isFullMode) {
    showCloseButton = false;
    showLauncher = false;
    isChatOpen = true;
  }

  return {
    extraMessageContext,
    getMoreMessagesEndpoint,
    isFullMode,
    showLauncher,
    showCloseButton,
    isChatOpen,
    messageList,
    placeholder,
    title,
    titleImageUrl,
    participants,
    newMessagesCount,
    icons: {
      open: {
        img: iconOpen,
        name: 'default',
      },
      close: {
        img: iconClose,
        name: 'default',
      },
      closeSvg: {
        img: iconCloseSvg,
        name: 'default',
      },
      file: {
        img: iconFile,
        name: 'default',
      },
    },
    // when set to a value matching the participant.id it shows the typing
    // indicator for the specific user
    showTypingIndicator: '',
    colors: colorscheme,
    // when set to true always scrolls the chat to the bottom when new events
    // are in (new message, user starts typing...)
    alwaysScrollToBottom: true,
    // enables *bold* /emph/ _underline_ and such (more info at
    // github.com/mattezza/msgdown)
    messageStyling: true,
    disableUerListToggle: true,
  };
}

